/**
 * Internal dependencies.
 */
import { initAnimations } from './animations';

/**
 * Handle load more click.
 */
$( '.js-load-more a' ).on( 'click', function( event ) {
	event.preventDefault();

	const $button = $( this );
	const $articleContainer = $button.closest( '.container' ).find( '.js-articles' );

	const postType = $button.attr( 'data-post-type' );
	const pageToLoad = $button.attr( 'data-page-to-load' );
	const fragment = $button.attr( 'data-fragment' );

	$.ajax( {
		// eslint-disable-next-line no-undef
		url: PHP.ajaxURL,
		data: {
			action: 'load_more',
			// eslint-disable-next-line camelcase
			page_to_load: pageToLoad,
			// eslint-disable-next-line camelcase
			post_type: postType,
			fragment,
		},
		beforeSend: () => {
			$button.addClass( 'is-loading' );
		},
		success: ( response ) => {
			if ( !response.success ) {
				return;
			}

			const { articles, nextPage, maxNumPages } = response.data;

			if ( nextPage > maxNumPages ) {
				$button.css( 'display', 'none' );
			}

			$articleContainer.append( articles );
			$button.attr( 'data-page-to-load', nextPage );
			initAnimations();
		},
		complete: () => {
			$button.removeClass( 'is-loading' );
		}
	} );
} );
